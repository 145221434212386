require(['jquery', 'table-dropdown-responsive'], function ($) {
  if ($('table').length > 0) {
    $('table').tableDropdownResponsive();
  }
  var contributeButtons = document.querySelectorAll('.contribute-popup');
  var popupButtonReerCeli = document.querySelector('.grille-bloc-questions .bloc-bta-wrapper a');
  var popupButtonReerCeliAvecConcours = document.querySelector('.grille-bloc-questions-avec-concours .bloc-bta-wrapper a');
  var popupButtonEpargne = document.querySelector('.grille-zone-importance-epargne .bloc-bta-wrapper a');
  var popupButtonZoneConcoursReerCeli = document.querySelector('.grille-zone-concours-reer .bloc-bta-wrapper a');
  if (contributeButtons && contributeButtons.length > 0) {
    if (popupButtonReerCeli) {
      contributeButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          popupButtonReerCeli.click();
        });
      });
    }
    if (popupButtonEpargne) {
      contributeButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          popupButtonEpargne.click();
        });
      });
    }
    if (popupButtonZoneConcoursReerCeli) {
      contributeButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          popupButtonZoneConcoursReerCeli.click();
        });
      });
    }
    if (popupButtonReerCeliAvecConcours) {
      contributeButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          popupButtonReerCeliAvecConcours.click();
        });
      });
    }
  }
});